<template>
  <div>
    <div class="flex items-start mt-4 mt-base mb-4">
      <h5 class="mt-1 font-medium">Images</h5>
      <vs-button class="ml-4" color="primary" @click="$refs.imageInput.click()" type="border" size="small">Add</vs-button>      
      <input type="file" hidden id="imageInput" ref="imageInput" v-on:change="onAddImage" accept="image/*" />
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material mb-3"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="imageList"
      :gridOptions="gridOptions"
      :rowDragManaged="true"
      :animateRows="true"
      domLayout="autoHeight"
      rowSelection="single"
      :pagination="false"
      :suppressPaginationPanel="true"
      :context="context"
      @first-data-rendered="onFirstDataRendered"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

  </div>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererImageActions from '../cell-renderers/CellRendererImageActions.vue'
import CellRendererImageThumbnail from '../cell-renderers/CellRendererImageThumbnail.vue'

export default {
  components: {
    AgGridVue,
    CellRendererImageActions,
    CellRendererImageThumbnail
  },
  props: {
    imageList: { type: Array, required: true, default: [] },
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererImageActions,
        CellRendererImageThumbnail
      },
      context: null,
      imagetInEdit: {},
      // imageList: [],
      currentImage: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', hide: true },
      { field: 'orderId', hide: true },
      { field: 'previewUrl', hide: true },
      { field: 'fileInput', hide: true },
      { field: 'entityStatusId', hide: true },
      { headerName: 'Thumbnail', rowDrag: true, width: 250,  suppressSizeToFit: false, cellRendererFramework: Vue.extend(CellRendererImageThumbnail), valueGetter: params => params.data.id },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererImageActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
    
    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

    this.gridOptions.onRowDragEnd = async (e) => { 
      //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

      const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      const rowNodeGettingDragged = e.node
      const fromIndex = this.$_.findIndex(this.imageList, x => x.id == rowNodeGettingDragged.data.id)
      const toIndex = e.overIndex
      array_move(this.imageList, fromIndex, toIndex)
      
      this.imageList = [...this.imageList]
      // emit new list here
      this.$emit('onSorted', this.imageList)
    }
  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onAddImage () {
      this.currentImage = this.$refs.imageInput.files[0];
      if (!this.currentImage) return

      const acceptedImageTypes = ['image/jpeg', 'image/png'];
      if(!acceptedImageTypes.includes(this.currentImage['type'])) {
        this.$_notifyFailure('Invalid file type, accepted file types are .jpeg and .png')
        return
      }
      
      const maxId = this.imageList.length > 0 ? Math.max(...this.imageList.map(o => o.id)) : 0
      this.$emit('onAdd', {
        id: maxId + 1,
        fileInput: this.currentImage,
        previewUrl: URL.createObjectURL(this.currentImage),
        orderId: this.imageList ? this.imageList.length + 1 : 1,
        roomId: null,
        entityStatusId: 1 // Added
      }) 
    },
    handleDelete(data) {
      this.$emit('onDelete',data)
    },
  }
}
</script>

<style>

</style>