<template>
  <div>
    <div class="flex items-start mt-4 mt-base mb-4">
      <h5 class="mt-1 font-medium">Rooms</h5>
      <vs-button class="ml-4" color="primary" @click="onAddRoom" type="border" size="small">Add</vs-button>      
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material mb-3"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="roomList"
      :gridOptions="gridOptions"
      domLayout="autoHeight"
      rowSelection="single"
      :pagination="false"
      :suppressPaginationPanel="true"
      :context="context"
      @first-data-rendered="onFirstDataRendered"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

    <room-edit-modal v-if="showModal"
      :roomData="roomInEdit"
      :formData="formData"
      :isNew="isModalAdding"
      @onEdit="handleEdit"
      @onAdd="handleAdd"
      @onCancel="showModal=false"
    />
    
  </div>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererRoomActions from '../cell-renderers/CellRendererRoomActions.vue'
import  RoomEditModal from './RoomEditModal.vue'

export default {
  components: {
    AgGridVue,
    CellRendererRoomActions,
    RoomEditModal
  },
  props: {
    roomList: { type: Array, required: true, default: [] },
    propertyImages: { type: Array, required: true, default: [] },
    formData: { required: true, type: Object },
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererRoomActions
      },
      context: null,
      showModal: false,
      isModalAdding: true,
      roomInEdit: {}
      // roomList: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    // this.roomList = [...this.data]
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'roomId', hide: true },
      { headerName: 'Name', field: 'roomName', width: 250, suppressSizeToFit: false },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererRoomActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
    
    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onAddRoom () {
      const maxId = this.roomList.length > 0 ? Math.max(...this.roomList.map(o => o.roomId)) : 0
      const newRoomId = maxId + 1
      const roomImages = this.propertyImages.filter(x => !x.roomId || x.roomId == newRoomId)
      this.roomInEdit = {
        roomId: newRoomId,
        roomName: null,
        roomContents: [],
        roomImages: roomImages,
        entityStatusId: 1 // Added
      }
      this.showModal = true
      this.isModalAdding = true
    },
    onEditRoom (data) {
      const roomImages = this.propertyImages.filter(x => !x.roomId || x.roomId == data.roomId)
      this.roomInEdit = Object.assign({}, data, { roomImages: roomImages })
      this.showModal = true
      this.isModalAdding = false
    },
    handleEdit(data) {
      this.showModal = false
      this.$emit('onEdit',data)
    },
    handleAdd(data) {
      this.showModal = false
      this.$emit('onAdd',data)
    },
    onDeleteRoom(roomId) {
      this.$emit('onDelete', roomId)
    }
  }
}
</script>

<style>

</style>