<template>
  <travio-modal width='450' height="80" zIndex="99999">
    <h3 slot="header">Add Linked Application</h3>
    <div slot="body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="">App Id</label>
          <vs-input class="w-full" type="number" v-model.number="linkedApplicationId" v-validate="'required'" name="Application Id" />
          <span class="text-danger text-sm">{{ errors.first('Application Id') }}</span>
        </div>
      </div>
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="saveLinkedApp" type="filled">Add</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
export default {
  components: {
    TravioModal
  },
  data () {
    return {
      linkedApplicationId: null
    }
  },
  props: {
   
  },
  computed: {
   
  },
  mounted (){
    this.linkedApplicationId = null
  },
  methods: {
    async saveLinkedApp () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      this.$vs.loading()

      try {
        let response = await this.$http.get(`api/applications/${this.linkedApplicationId}/basicInfo`)
        
        if(!response.data) {
          this.$vs.loading.close()  
          this.$_notifyFailure('App id is not valid, please enter a valid app id.')
          return
        }
        this.$emit('onAdd', Object.assign(response.data, { entityStatusId: 1 }))
        this.close()
      }
      catch (error) {
        this.$_notifyFailure(error)
      }
      this.$vs.loading.close()  
    },
    close () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>