<template>
  <div class="flex items-center justify-center cell-renderer-room-image-checkbox-container">
    <vs-checkbox v-model="params.data.isAssigned"  @change="enable" />
  </div>
</template>

<script>
export default {
  methods: {
    enable(value) {
      
      this.params.context.componentParent.assignImageToRoom(this.params.data) 
    }
  }
}
</script>

<style scoped>
/* Make sure the checkbox centers vertically in the row*/
.cell-renderer-room-image-checkbox-container {
  height: inherit;
}
</style>