<template>
  <travio-modal width='550' height="650" zIndex="52000">
    <h3 slot="header">{{headingTitle}}</h3>
    <div slot="body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="">Room Name (must match name in API)</label>
          <vs-input class="w-full" v-model="roomName" v-validate="'required|max:100'" name="Name" />
          <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
        </div>
        <div class="vx-col w-full mt-3">
          <label class="">Room Rename</label>
          <vs-input class="w-full" v-model="roomRename" v-validate="'max:100'" name="Rename" />
          <span class="text-danger text-sm">{{ errors.first('Rename') }}</span>
        </div>
      </div>
      
      <div class="vx-row">
        <div class="vx-col w-1/3 mt-3">
          <label class="">Sleeps Total</label>
          <vs-input class="w-full" type="number" v-model.number="sleepsTotal" name="Sleeps Total" v-validate="'greaterThanOrEqualToZero'"/>
          <span class="text-danger text-sm">{{ errors.first('Sleeps Total') }}</span>
        </div>
        <div class="vx-col w-1/3 mt-3">
          <label class="">Sleeps Adult</label>
          <vs-input class="w-full" type="number" v-model.number="sleepsAdult" name="Sleeps Adult" v-validate="'greaterThanOrEqualToZero'"/>
          <span class="text-danger text-sm">{{ errors.first('Sleeps Adult') }}</span>
        </div>
        <div class="vx-col w-1/3 mt-3">
          <label class="">Sleeps Children</label>
          <vs-input class="w-full" type="number" v-model.number="sleepsChildren" name="Sleeps Children" v-validate="'greaterThanOrEqualToZero'"/>
          <span class="text-danger text-sm">{{ errors.first('Sleeps Children') }}</span>
        </div>
      </div>

      <div class="flex items-start mt-6">
        <h5 class="mt-1 font-medium">Room Description</h5>
        <vs-button class="ml-4" color="primary" @click="onAddContent" type="border" size="small">Add</vs-button>      
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :components="cellRendererComponents"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="roomContents"
        :gridOptions="gridOptions"
        rowSelection="single"
        :pagination="false"
        :suppressPaginationPanel="true"
        :context="context"
        :style="{ height:'180px' }"
      ></ag-grid-vue>

      <div class="flex items-start mt-2 mb-4">
        <h5 class="mt-1 font-medium">Room Images (upload via the main property page)</h5>
      </div>
      <room-image-list 
        :imageList="roomImages"
        @onAssignImageToRoom="handleAssignImageToRoom"
      />

      <room-content-modal v-if="showContentModal"
        :data="roomContentInEdit"
        :formData="formData"
        :isNew="isModalAdding"
        @onAdd="handleContentAdded"
        @onEdit="handleContentUpdated"
        @onCancel="handleContentModalCancel"
      />

      
    </div>

    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="save" type="filled">{{saveButtonText}}</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererRoomContentActions from '../cell-renderers/CellRendererRoomContentActions.vue'
import RoomContentModal from './RoomContentModal.vue'
import RoomImageList from './RoomImageList.vue'
import Vue from 'vue'

export default {
  components: {
    TravioModal,
    CellRendererRoomContentActions,
    AgGridVue,
    RoomContentModal,
    RoomImageList,
    
  },
  data () {
    return {
      roomId: 0,
      roomName: '',
      roomRename: '',
      sleepsTotal: null,
      sleepsAdult: null,
      sleepsChildren: null,
      roomContents: [],
      roomImages: [],
      entityStatusId: 0,
      autoGroupColumnDef: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      context: null,
      cellRendererComponents: {
        CellRendererRoomContentActions
      },
      roomContentInEdit: {},
      showContentModal: false,
      hasLoaded: false,
      isAddingContent: true,
    }
  },
  props: {
    roomData: { required: true, type: Object }, 
    formData: { required: true, type: Object }, 
    // Note that entity id can be 1 = added but isNew can still be false since it can be edited again but not yet saved in db
    isNew:  { required: true, type: Boolean },
  },
  computed: {
    headingTitle() {
      return this.entityStatusId == 1 ? 'Add Room': 'Update Room'
    },
    saveButtonText () {
      return this.isNew ? 'Add': 'Update'
    }
  },
  created () {
    this.roomId = this.roomData.roomId
    this.roomName = this.roomData.roomName
    this.roomRename = this.roomData.roomRename
    this.sleepsTotal = this.roomData.sleepsTotal
    this.sleepsAdult = this.roomData.sleepsAdult
    this.sleepsChildren = this.roomData.sleepsChildren
    this.roomContents = [...this.roomData.roomContents]
    this.entityStatusId = this.roomData.entityStatusId
    this.roomImages = [...this.roomData.roomImages]
    // this.roomImages = [
    //   { imageId: 1, previewUrl: "https://staticdev.travelify.io/properties/67.jpg", entityStatusId: 0, isAssigned: false },
    //   { imageId: 2, previewUrl: "https://staticdev.travelify.io/properties/66.png", entityStatusId: 0, isAssigned: true }
    // ]

    this.$validator.extend(
      'greaterThanOrEqualToZero',{
      getMessage: field =>  field + ' needs to be greater than or equals zero.',
      validate: (value) => {
        
        // value must be >= zero
        if (value >= 0 ) return true;
        return false;
      }
    });

  },
  beforeMount() {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'Language', field: 'language', sortable: false, width: 130, suppressSizeToFit: false },
      { headerName: 'Actions', width: 120,  sortable: false, suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererRoomContentActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      autoHeight: true,
      resizable: true,
      suppressMenu: true
    }

    
    //This will make methods from this accessible to cell renderer
    this.context = { componentParent: this }
  },
  mounted (){
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

  },
  methods: {
    onAddContent () {
      this.roomContentInEdit = {
        language: 'en',
        contentText: null,
        entityStatusId: 1
      }
      this.showContentModal = true
      this.isModalAdding = true
    },
    handleContentAdded (data) {
      const foundIndex = this.roomContents.findIndex(x => x.language.toLowerCase() == data.language.toLowerCase())
      
      if(foundIndex !== -1) {
        this.$_notifyFailure(data.language.toUpperCase() + ' language already exists')
        return
      }

      this.roomContents.push(data)
      this.showContentModal=false
    },
    handleContentUpdated (data) {
      const foundIndex = this.roomContents.findIndex(x => x.language.toLowerCase() == data.language.toLowerCase())
      this.roomContents[foundIndex] = data
      this.roomContents = [...this.roomContents]
      this.showContentModal=false
    },
    handleContentModalCancel () {
      this.showContentModal = false
    },
    handleAssignImageToRoom (data) {
      const foundIndex = this.roomImages.findIndex(x => x.id == data.id)
      if(this.roomImages[foundIndex].entityStatusId != 1) {
        //Only set to modified if image is not new/added
        this.roomImages[foundIndex].entityStatusId = 2 
      }
    },
    async save () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      if(this.isNew) {
        this.$emit('onAdd', { 
          roomId: this.roomId,
          roomName: this.roomName,
          roomRename: this.roomRename,
          sleepsTotal: this.sleepsTotal,
          sleepsAdult: this.sleepsAdult,
          sleepsChildren: this.sleepsChildren,
          roomContents: this.roomContents,
          roomImages: this.roomImages,
          entityStatusId: this.entityStatusId
        })
      } else {
        this.entityStatusId = this.entityStatusId == 0 ? 2 : this.entityStatusId  // set to modified
        this.$emit('onEdit', { 
          roomId: this.roomId,
          roomName: this.roomName,
          roomRename: this.roomRename,
          sleepsTotal: this.sleepsTotal,
          sleepsAdult: this.sleepsAdult,
          sleepsChildren: this.sleepsChildren,
          roomContents: this.roomContents,
          roomImages: this.roomImages,
          entityStatusId: this.entityStatusId
        })
      }
      this.close()
    },
    close () {
      this.$emit('onCancel')
    },
    onEditRoomContent(data) {
      this.roomContentInEdit = data
      this.showContentModal = true
      this.isModalAdding = false
    },
    onDeleteRoomContent(data) {
      // Room contents has no id, Room ID and Language is the composite primary key
      this.roomContents = this.roomContents.filter(x => x.language !== data.language)
    },
    
    
  }
}
</script>

<style>
  .fixed-height-room-modal {
    height: calc(var(--vh, 1vh) * 100 - 19.7rem);
    overflow-y: scroll;
    position: relative;
    margin:auto
  }
</style>
