<template>
  <div>
    <div class="flex items-start mt-4 mt-base mb-4">
      <h5 class="mt-1 font-medium">Linked Applications</h5>
      <vs-button class="ml-4" color="primary" @click="onAddApp" type="border" size="small">Add</vs-button>      
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material mb-3"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="appList"
      :gridOptions="gridOptions"
      :rowDragManaged="true"
      :animateRows="true"
      domLayout="autoHeight"
      rowSelection="single"
      :pagination="false"
      :suppressPaginationPanel="true"
      :context="context"
      @first-data-rendered="onFirstDataRendered"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

    <linked-application-modal v-if="showModal"
      @onAdd="handleAddApp"
      @onCancel="showModal=false"
    />

  </div>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererAppActions from '../cell-renderers/CellRendererAppActions.vue'
import LinkedApplicationModal from './LinkedApplicationModal.vue'

export default {
  components: {
    AgGridVue,
    CellRendererAppActions,
    LinkedApplicationModal
  },
  props: {
    appList: { type: Array, required: true, default: [] },
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererAppActions
      },
      context: null,
      showModal: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
  },
  beforeMount () {
    this.gridOptions = {};

    /// Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { headerName: 'App Id', field: 'applicationId', width: 120, suppressSizeToFit: true },
      { headerName: 'App Name', field: 'applicationName', width: 200, suppressSizeToFit: false },
      { field: 'entityStatusId', hide: true },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererAppActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
    
    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onAddApp () {
      this.showModal = true
    },
    handleAddApp (appId) {
      this.$emit('onAdd', appId) 
    },
    handleDelete(appId) {
      this.$emit('onDelete',appId)
    },
  }
}
</script>

<style>

</style>