  <template>

  <travio-center-container grid-width='1/2'>
    <h2 class="mb-4" style="color:#636363">{{formTitle}}</h2>
    <vx-card>
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Engine Reference*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.reference" v-validate="'required|max:20'" name="Engine Reference" />
          <span class="text-danger text-sm">{{ errors.first('Engine Reference') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Supplier</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select 
            v-model="form.supplierId" 
            :reduce="(option) => option.code" 
            name="Supplier" 
            :options="formData.supplierOptions" :clearable="false"
            v-validate="'required'" />
        </div>
        <span class="text-danger text-sm">{{ errors.first('Supplier') }}</span>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.name" v-validate="'required|max:100'" name="Name" />
          <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Property Type</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="form.propertyTypeId" :reduce="(option) => option.code" name="Property Type" :options="formData.propertyTypeOptions" :clearable="false" />
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Rating</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="form.rating" :reduce="(option) => option.code" name="Rating" :options="ratingOptions" :clearable="false" />
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Address</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.address" v-validate="'max:100'" name="Address" />
          <span class="text-danger text-sm">{{ errors.first('Address') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>City*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.city" v-validate="'required|max:25'" name="City" />
          <span class="text-danger text-sm">{{ errors.first('City') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>State</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.state" v-validate="'max:25'" name="State" />
          <span class="text-danger text-sm">{{ errors.first('State') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Postal Code</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.postalCode" v-validate="'max:15'" name="Postal Code" />
          <span class="text-danger text-sm">{{ errors.first('Postal Code') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Country*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select
            v-model="form.countryCode"
            name="Country"
            v-validate="'required'"
            :reduce="(x) => x.code"
            :options="formData.countryOptions"
          />
          <span class="text-danger text-sm">{{ errors.first('Country') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Latitude*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input
            class="w-full"
            v-model.number="form.latitude"
            type="number"
            name="Latitude"
            v-validate="'required|min_value:-90|max_value:90'"
          />
          <span class="text-danger text-sm">{{ errors.first('Latitude') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Longitude*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input
            class="w-full"
            v-model.number="form.longitude"
            type="number"
            name="Longitude"
            v-validate="'required|min_value:-180|max_value:180'"
          />
          <span class="text-danger text-sm">{{ errors.first('Longitude') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Is Active</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-switch
            class=""
            v-model="form.isActive"
          />
        </div>
      </div>

      <content-list
        :contentList="contentList"
        :formData="formData"
        @onEdit="handleEditContent"
        @onAdd="handleAddContent"
        @onSorted="handleChangeSorted"
        @onDelete="handleDeleteContent"
      />
      <image-list
        :imageList="imageList"
        @onAdd="handleAddImage"
        @onDelete="handleDeleteImage"
        @onSorted="handleChangeImageSorted"
      />
      <room-list
        :roomList="roomList"
        :propertyImages="imageList"
        :formData="formData"
        @onEdit="handleEditRoom"
        @onAdd="handleAddRoom"
        @onDelete="handleDeleteRoom"
      />
      <linked-applications
        :appList="linkedApplications"
        @onAdd="handleAddApp"
        @onDelete="handleDeleteApp"
      />

      <div class="flex flex-wrap justify-end">
          <vs-button class="" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
          <vs-button class="ml-2" color="primary" @click="onSave" type="filled">Save</vs-button>      
      </div>
    </vx-card>

  </travio-center-container>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'
import ContentList from './components/ContentList.vue'
import ImageList from './components/ImageList.vue'
import LinkedApplications from './components/LinkedApplications.vue'
import RoomList from './components/RoomList.vue'


export default {
  components: {
    ContentList,
    ImageList,
    LinkedApplications,
    RoomList
  },
  props: {
    applicationId: { required: true },
    propertyId: { required: false, default: 0 },
  },
  data () {
    return {
      form: new Form({
        propertyId: 0,
        propertyTypeId: null,
        reference: '',
        name: '',
        rating: null,
        supplierId: null,
        address: '',
        city: '',
        state: '',
        postalCode: '',
        countryCode: '',
        latitude: null,
        longitude: null,
        isActive: true,
      }),
      ratingOptions: [
        { code: null, label: 'Unrated' },
        { code: 1, label: '1.0' },
        { code: 1.5, label: '1.5' },
        { code: 2.0, label: '2.0' },
        { code: 2.5, label: '2.5' },
        { code: 3.0, label: '3.0' },
        { code: 3.5, label: '3.5' },
        { code: 4.0, label: '4.0' },
        { code: 4.5, label: '4.5' },
        { code: 5.0, label: '5.0' },
      ],
      formData: {
        countryOptions: [],
        contentTypeOptions: [],
        languageOptions: [],
        propertyTypeOptions: [],
        supplierOptions: [],
      },
      contentList: [],
      imageList: [],
      linkedApplications: [],
      roomList: [],
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    formTitle() {
      return this.propertyId == 0 ? 'Add Property' : 'Edit Property'
    },
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    this.$vs.loading()
    try {
      if (this.propertyId) {
        // Edit
        const response = await this.$http.get(`api/customProperties/apps/${this.applicationId}/${this.propertyId}`)
        const { formData , contents, images, linkedApplications, rooms, ...responseNoCountryOptions } = response.data;
        this.formData = formData
        this.contentList = [...contents],
        this.imageList = [...images]
        this.linkedApplications = [...linkedApplications]
        this.roomList = [...rooms]
        this.form = Object.assign(this.form, responseNoCountryOptions)

      } else {
        const response = await this.$http.get(`api/customProperties/apps/${this.applicationId}/formData`)
        this.formData = response.data
      }

    } catch (error) {
      this.$_notifyFailure(error)
    }

    this.$vs.loading.close();

  },
  mounted() {

  },
  methods: {
    async onSave (){

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      this.$vs.loading()
      try {

        const payload = this.form.data()
        const keys = Object.keys(payload);
        let formData = new FormData();
        keys.forEach((key, index) => {
          formData.append(key, payload[key])
          // Appending null to formdata converts it to 'null', delete the key to set it to null
          if (payload[key] == null) {
            formData.delete(key)
          }
        });
        if( this.contentList ) {
          this.contentList.forEach((elem, index) => {
            const contentKeys = Object.keys(elem);
            contentKeys.forEach((contentKey, contentIndex) => {
              formData.append(`contents[${index}].${contentKey}`, elem[contentKey] )  
              // Appending null to formdata converts it to 'null', delete the key to set it to null
              if (elem[contentKey] == null) {
                formData.delete(`contents[${index}].${contentKey}`)
              }
            })
          })
        }

        if( this.imageList ) {
          this.imageList.forEach((elem, index) => {
            const imageKeys = Object.keys(elem);
            imageKeys.forEach((imageKey, imageIndex) => {
              formData.append(`images[${index}].${imageKey}`, elem[imageKey] )  
              // Appending null to formdata converts it to 'null', delete the key to set it to null
              if (elem[imageKey] == null) {
                formData.delete(`images[${index}].${imageKey}`)
              }
            })
          })
        }

        if( this.linkedApplications ) {
          this.linkedApplications.forEach((elem, index) => {
            const linkedAppKeys = Object.keys(elem);
            linkedAppKeys.forEach((linkedAppKey, linkedAppIndex) => {
              formData.append(`linkedApplications[${index}].${linkedAppKey}`, elem[linkedAppKey] )  
              // Appending null to formdata converts it to 'null', delete the key to set it to null
              if (elem[linkedAppKey] == null) {
                formData.delete(`linkedApplications[${index}].${linkedAppKey}`)
              }
            })
          })
        }

        if( this.roomList ) {
          this.roomList.forEach((elem, index) => {
            const roomKeys = Object.keys(elem);
            roomKeys.forEach((roomKey, roomKeyIndex) => {
              if(roomKey == 'roomContents') {
                  let roomContents = elem[roomKey]
                  if(roomContents && roomContents.length > 0) {
                    roomContents.forEach((elem2, index2) => {
                      const roomContentKeys = Object.keys(elem2)
                      roomContentKeys.forEach((roomContentKey, roomContentKeyIndex) => {
                        const formKey = `roomList[${index}].roomContents[${index2}].${roomContentKey}`
                        formData.append(formKey, elem2[roomContentKey] )  
                        // Appending null to formdata converts it to 'null', delete the key to set it to null
                        if (elem2[roomContentKey] == null) {
                          formData.delete(formKey)
                        }
                      })
                    })
                  }

              } else {
                formData.append(`roomList[${index}].${roomKey}`, elem[roomKey] )  
                // Appending null to formdata converts it to 'null', delete the key to set it to null
                if (elem[roomKey] == null) {
                  formData.delete(`roomList[${index}].${roomKey}`)
                }
              }
              
            })
          })
        }

        if (this.propertyId) {
          // const response = await this.$http.put(`api/customProperties/apps/${this.applicationId}`, {...this.form.data(), contents: this.contentList })
          const response = await this.$http.put(`api/customProperties/apps/${this.applicationId}`, 
            formData, 
            { headers: { 'Content-Type': 'multipart/form-data'} }
          )
          this.$_notifySuccess('Successfully updated property');
        } else {
          // const response = await this.$http.post(`api/customProperties/apps/${this.applicationId}`, {...this.form.data(), contents: this.contentList } )
          const response = await this.$http.post(`api/customProperties/apps/${this.applicationId}`, 
            formData, 
            { headers: { 'Content-Type': 'multipart/form-data'} }
          )
          this.$_notifySuccess('Successfully created property');
        }
        this.onCancel()



      } catch (error) {
        this.$_notifyFailure(error)
      }

      this.$vs.loading.close();
      
    },
    handleChangeSorted (data) {
      // replace list with an updated sorting
      data.forEach((element, index) => {
        element.orderId = index + 1
        // If entity status is NoChange then set it Modified, if added then stay the same
        element.entityStatusId = element.entityStatusId == 0 ? 2 : element.entityStatusId
      });
      this.contentList = [...data]
    },
    onCancel () {
      this.$router.push({name: 'application-tools-property-content', 
        params: { applicationId: this.applicationId }
      })
    },
    handleEditContent(data) {
      const foundIndex = this.contentList.findIndex(x => x.id == data.id)
      this.contentList[foundIndex] = data
      this.contentList = [...this.contentList]
    },
    handleEditRoom (data) {
      const foundIndex = this.roomList.findIndex(x => x.roomId == data.roomId)
      const { roomImages, ...roomDataWithoutImages } = data;
      this.roomList[foundIndex] = roomDataWithoutImages
      this.roomList = [...this.roomList]
      // Property image can only be assign to one room 
      let updatedRoomImageAssociation = roomImages && roomImages.filter(x => x.entityStatusId == 1 || x.entityStatusId == 2)
      if(updatedRoomImageAssociation && updatedRoomImageAssociation.length > 0) {
        updatedRoomImageAssociation.forEach((elem, index) => {
          let image = this.imageList.find(x => x.id === elem.id)
          // Assign or disassign
          image.roomId = elem.isAssigned ? data.roomId : null
          if(image.entityStatusId != 1) {
            // only assigne modified if not added
            image.entityStatusId = 2
          }
        });
        
      }
    },
    handleAddContent (data) {
      if(data) {
        this.contentList.push(data)
      }
    },
    handleAddImage (data) {
      if(data) {
        this.imageList.push(data)
      }
    },
    handleAddRoom (data) {
      const { roomImages, ...roomDataWithoutImages } = data;

      if(roomDataWithoutImages) {
        this.roomList.push(roomDataWithoutImages)
      }

      let associatedImages = roomImages || []
      if(associatedImages && associatedImages.length > 0) {
        associatedImages.forEach((elem, index) => {
          let image = this.imageList.find(x => x.id === elem.id)
          // Assign or disassign
          image.roomId = elem.isAssigned ? data.roomId : null
          if(image.entityStatusId != 1) {
            // only assigne modified if not added
            image.entityStatusId = 2
          }
        });
        
      }
      
    },
    handleDeleteImage (imageId) {
      this.imageList = this.imageList.filter(x => x.id != imageId)
    },
    handleChangeImageSorted (data) {
    // replace list with an updated sorting
      data.forEach((element, index) => {
        element.orderId = index + 1
        // If entity status is NoChange then set it Modified, if added then stay the same
        element.entityStatusId = element.entityStatusId == 0 ? 2 : element.entityStatusId
      });
      this.imageList = [...data]
    },
    handleDeleteContent (id) {
      this.contentList = this.contentList.filter(x => x.id != id)
    },
    handleDeleteApp (appId) {
      this.linkedApplications = this.linkedApplications.filter(x => x.applicationId != appId)
    },
    handleDeleteRoom (id) {
      this.roomList = this.roomList.filter(x => x.roomId != id)
    },
    handleAddApp (data) {
      if(data) {
        this.linkedApplications.push(data)
      }
    },
   
  }
}
</script>

<style>

</style>