<template>
  <div>
    <feather-icon title="Delete" icon="TrashIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showDeletePopup=true" />
    <vs-prompt
      title="Remove Linked App"
      accept-text="Remove"
      @accept="onDeleteApp"
      :active.sync="showDeletePopup">

      <div class="vx-row mt-2 mb-2">
        <div class="vx-col w-full">
          <div class="">Are you sure you want to remove app {{ params.data.applicationName }}?</div>
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onDeleteApp () {
      this.params.context.componentParent.handleDelete(this.params.data.applicationId) 
    }
  }
}
</script>
