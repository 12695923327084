<template>
  <travio-modal width='550' height="460" zIndex="99999">
    <h3 slot="header">{{headingTitle}}</h3>
    <div slot="body">
      <div class="vx-row">
        <div class="vx-col w-full">
          <label class="">Title</label>
          <vs-input class="w-full" v-model="content.title" v-validate="'required|max:100'" name="Title" />
          <span class="text-danger text-sm">{{ errors.first('Title') }}</span>
        </div>
         <div class="vx-col w-full mt-4">
          <label class="">Content Type</label>
          <v-select
            :value="content.typeId"
            name="Content Type"
            v-validate="'required'"
            @input="setSelectedContentType"
            :reduce="(x) => x.code"
            :options="formData.contentTypeOptions"
          />
          <span class="text-danger text-sm">{{ errors.first('Content Type') }}</span>
        </div>
        <div class="vx-col w-full mt-4">
          <label class="">Language</label>
          <v-select
            v-model="content.language"
            name="Language"
            v-validate="'required'"
            :reduce="(x) => x.code.toLowerCase()"
            :options="formData.languageOptions"
          />
          <span class="text-danger text-sm">{{ errors.first('Language') }}</span>
        </div>
        <div class="vx-col w-full mt-4">
          <label class="">Content Text</label>
          <quill-editor
            name="Content Text"
            v-validate="'required'"
            style="height: 160px"
            v-model="content.contentText"
            class="w-full"
            :options="editorOption"
          />
          <div class="mt-12">
            <span class="text-danger text-sm">{{ errors.first('Content Text') }}</span>
          </div>
        </div>
      </div>
    </div>
    
    <div slot="footer" class="flex flex-wrap justify-end">
      <vs-button class="" color="danger" @click="close" type="filled">Cancel</vs-button>      
      <vs-button class="ml-2" color="primary" @click="saveContent" type="filled">{{buttonText}}</vs-button>      
    </div>
  </travio-modal>
</template>

<script>
import TravioModal from '@/components/travio-pro/TravioModal.vue'
import { quillEditor } from 'vue-quill-editor'
import debounce from 'lodash/debounce'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

export default {
  components: {
    TravioModal,
    quillEditor
  },
  data () {
    return {
      content: {
        id: 0,
        title: null,
        language: 'en',
        typeId: null,
        type: null,
        orderId: 0,
        contentText: null,
        entityStatusId: 0
      },
      editorOption: {
        modules : {
          toolbar : [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }, { 'color': [] }],
          ]
        }
      }
    }
  },
  props: {
    data: { required: true, type: Object }, 
    formData: { required: true, type: Object },
    // Note that entity id can be 1 = added but isNew can still be false since it can be edited again but not yet saved in db
    isNew:  { required: true, type: Boolean },
  },
  computed: {
    headingTitle() {
      return this.content.entityStatusId == 1 ? 'Add Content': 'Edit Content'
    },
    buttonText() {
      return this.isNew ? 'Add': 'Update'
    }
  },
  created () {
    this.content = Object.assign({}, this.data)
  },
  mounted (){

  },
  methods: {
    async saveContent () {
      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }
      if(this.isNew) {
        this.$emit('onAdd', this.content)
      } else {
        this.content.entityStatusId = this.content.entityStatusId == 0 ? 2 : this.content.entityStatusId  // set to modified
        this.$emit('onEdit', this.content)
      }

    },
    setSelectedContentType (value) {
      const contentType = this.formData.contentTypeOptions.find(x => x.code == value)
      this.content.typeId = contentType && contentType.code
      this.content.type = contentType && contentType.label
    },
    close () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style>

</style>