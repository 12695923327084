<template>
  <div>
    <div class="flex items-start mt-4 mt-base mb-4">
      <h5 class="mt-1 font-medium">Contents</h5>
      <vs-button class="ml-4" color="primary" @click="onAddContent" type="border" size="small">Add</vs-button>      
    </div>
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material mb-3"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="contentList"
      :gridOptions="gridOptions"
      :rowDragManaged="true"
      :animateRows="true"
      domLayout="autoHeight"
      rowSelection="single"
      :pagination="false"
      :suppressPaginationPanel="true"
      :context="context"
      @first-data-rendered="onFirstDataRendered"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

    <content-modal v-if="showModal"
      :data="contentInEdit"
      :formData="formData"
      :isNew="isModalAdding"
      @onEdit="handleEdit"
      @onAdd="handleAdd"
      @onCancel="showModal=false"
    />
  </div>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererContentActions from '../cell-renderers/CellRendererContentActions.vue'
import  ContentModal from './ContentModal.vue'

export default {
  components: {
    AgGridVue,
    CellRendererContentActions,
    ContentModal
  },
  props: {
    contentList: { type: Array, required: true, default: [] },
    formData: { type: Object, required: true, default: {} },
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererContentActions
      },
      context: null,
      showModal: false,
      isModalAdding: true,
      contentInEdit: {}
      // contentList: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
    // this.contentList = [...this.data]
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'id', hide: true },
      { field: 'orderId', hide: true },
      { field: 'contentText', hide: true },
      { field: 'typeId', hide: true },
      { headerName: 'Title', rowDrag: true, field: 'title', width: 250, suppressSizeToFit: false },
      { headerName: 'Lang', field: 'language', width: 90, suppressSizeToFit: true },
      { headerName: 'Content Type', field: 'type', width: 230, suppressSizeToFit: true },
      { headerName: 'Actions', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererContentActions) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
    
    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();

    this.gridOptions.onRowDragEnd = async (e) => { 
      //https://www.ag-grid.com/vue-grid/row-dragging/#row-drag-events

      const array_move = (arr, old_index, new_index) => {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      }

      const rowNodeGettingDragged = e.node
      const fromIndex = this.$_.findIndex(this.contentList, x => x.id == rowNodeGettingDragged.data.id)
      const toIndex = e.overIndex
      array_move(this.contentList, fromIndex, toIndex)
      
      this.contentList = [...this.contentList]
      // emit new list here
      this.$emit('onSorted', this.contentList)
    }
  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    onAddContent () {
      const maxId = this.contentList.length > 0 ? Math.max(...this.contentList.map(o => o.id)) : 0
      this.contentInEdit = {
        id: maxId + 1, // This is just artificial Id, so we can still perform query
        title: null,
        language: 'en',
        typeId: null,
        type: null,
        orderId: this.contentList ? this.contentList.length + 1 : 1,
        contentText: null,
        entityStatusId: 1 // Added
      }
      this.showModal = true
      this.isModalAdding = true
    },
    onEditContent (data) {
      this.contentInEdit = Object.assign({}, data)
      this.showModal = true
      this.isModalAdding = false
    },
    handleEdit(data) {
      this.showModal = false
      this.$emit('onEdit',data)
    },
    handleAdd(data) {
      this.showModal = false
      this.$emit('onAdd',data)
    },
    handleDelete(id) {
      this.$emit('onDelete', id)
    }
  }
}
</script>

<style>

</style>