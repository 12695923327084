<template>
  <div>
    <ag-grid-vue
      ref="agGridTable"
      :components="cellRendererComponents"
      class="ag-theme-material mb-3"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="imageList"
      :gridOptions="gridOptions"
      domLayout="autoHeight"
      rowSelection="single"
      :pagination="false"
      :suppressPaginationPanel="true"
      :context="context"
      :style="{ height:'200px' }"
      @first-data-rendered="onFirstDataRendered"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

  </div>
</template>

<script>

import Vue from "vue"
import {AgGridVue} from "ag-grid-vue";
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import CellRendererRoomImageActiveCheckbox from '../cell-renderers/CellRendererRoomImageActiveCheckbox.vue'
import CellRendererRoomImageThumbnail from '../cell-renderers/CellRendererRoomImageThumbnail.vue'

export default {
  components: {
    AgGridVue,
    CellRendererRoomImageActiveCheckbox,
    CellRendererRoomImageThumbnail
  },
  props: {
    imageList: { type: Array, required: true, default: [] },
  },
  data () {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
      rowSelection: null,
      cellRendererComponents: {
        CellRendererRoomImageActiveCheckbox,
        CellRendererRoomImageThumbnail
      },
      context: null,
      imagetInEdit: {},
      // imageList: [],
      currentImage: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  created () {
  },
  beforeMount () {
    this.gridOptions = {};

    // Vue.extend for CellRenderer is not documented, see https://github.com/ag-grid/ag-grid/issues/3575 instead
    this.columnDefs =  [
      { field: 'imageId', hide: true },
      { field: 'previewUrl', hide: true },
      { field: 'entityStatusId', hide: true },
      { headerName: 'Thumbnail', width: 250,  suppressSizeToFit: false, cellRendererFramework: Vue.extend(CellRendererRoomImageThumbnail), valueGetter: params => params.data.id },
      { headerName: 'Assigned', field: 'isAssigned', width: 120,  suppressSizeToFit: true, cellRendererFramework: Vue.extend(CellRendererRoomImageActiveCheckbox) },
    ]

    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      sortable: true,
      resizable: true,
      autoHeight: true,
      suppressMenu: true
    }
    
    this.context = { componentParent: this }
  },
  mounted () {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;
    // See https://www.ag-grid.com/javascript-grid-resizing/#size-columns-to-fit
    this.gridApi.sizeColumnsToFit();
    
  },
  methods: {
    onGridReady () {
      this.gridApi.sizeColumnsToFit && this.gridApi.sizeColumnsToFit()
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit();
    },
    assignImageToRoom (data) {
      this.$emit('onAssignImageToRoom', data)
    },
    handleDelete(data) {
      this.$emit('onDelete',data)
    },
  }
}
</script>

<style>

</style>