<template>
  <div>
    <feather-icon title="Delete" icon="TrashIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showDeletePopup=true" />
    <vs-prompt
      title="Delete Image"
      accept-text="Delete"
      @accept="onDeleteImage"
      :active.sync="showDeletePopup">

      <div class="vx-row mt-2 mb-2">
        <div class="vx-col w-full">
          <div class="">Are you sure you want to delete this image?</div>
          <div class="custom-property-image-container mt-3">
            <img :src="params.data.previewUrl" class="custom-property-image" />
          </div>
        </div>
      </div>
    </vs-prompt>
  </div>
</template>

<script>

export default {
  components: {
  },
  data () {
    return {
      showDeletePopup: false
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    }
  },
  methods: {
    onDeleteImage () {
      this.params.context.componentParent.handleDelete(this.params.data.id) 
    }
  }
}
</script>

<style>
  /* .file-list-container {
    height: 40px;
  } */
  .custom-property-image-container .custom-property-image {
    border-radius: 3px;
    height: 120px;
  }
</style>
